export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyCNQiBLXDN0CYqoLBYo4cMIfxC5l15YQj8",
        authDomain: "ecb.cwl.camp",
        projectId: "cwl-3f46d9ec79ac-prod"
    },
    region: 'europe-west1',
    brain_base_url: 'https://ecb.api.deloitte.camp',
    breadcrumbs: {
        assetOwner: {
            aoh: 'Asset Owner Hub',
            assetManagement: 'Asset Management',
            principleAssetQuestions: 'Principle asset questions',
            offerRequestDetail: 'Offer request detail',
            assetPreview: 'Preview',
        },
        store: {
            deloitteAiCatalog: 'Catalog',
            checkout: 'Checkout',
            standingOrderDetail: 'Standing order detail',
            storefront: 'Storefront',
            orderHistory: 'Order History',
            myEntitlements: "My Entitlements"
        },
    },
    aoh_url: 'https://aoh.ecb.cwl.camp/',
    store_url: 'https://ecb.cwl.camp/',
    api_base_url: 'https://ecb.api.deloitte.camp',
    authParams: ['oidc.campfire','oidc.ecb'],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    onboarding: {
        displayVideo: false,
        video_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4',
        development: {
            frontend_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf',
            backend_url: 'https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse',
            business_support_email: 'mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace',
        },
    },
    barrierQuestionsAbort: {
        mailAddress: 'mahorst@deloitte.de',
    },
    support: {
        ccEmail: "",
        contact: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace"
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://ecb-geniusai.connect.api.deloitte.ai",
            app_url: "https://geniusai.ecb.cwl.camp/home"
        },
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
